import React from 'react';
import "./Creators.scss";
import creators from '../../../assets/images/creators.png';
import aem from '../../../assets/images/aem.png';
import somas from '../../../assets/images/somas.png';
import separatorDesktop from '../../../assets/images/separator-desktop.png';
import separatorMobile from '../../../assets/images/separator-mobile.png';
import Button from "../../common/Button/Button";


const Creators = () => {
  const widthWindow = window.innerWidth;

  return (
    <div className='Creators' id='idealizadores'>
      <img className='creators-img' alt='Conheça os idealizadores' src={creators} />
      <div className='container-creators'>
        <div>
          <img alt='A&M' src={aem} />
          <p>Resultados significativos, com agilidade, segurança e impacto.
            Os mercados em crescimento da América Latina estão sujeitos a desafios constantes e mudanças diárias,
            relacionadas a cada país que compõe o bloco e as indústrias que sustentam as suas economias.
            Companhia privada desde a sua fundação, em 1983, a A&M é uma empresa líder mundial em serviços
            profissionais que oferece serviços de consultoria, aprimora-mento de desempenho de negócios
            e gestão de recuperação. Com o apoio de mais de 6 mil pessoas em cinco continentes,
            a A&M está posicionada de forma singular no mercado para elaborar e implementar
            soluções que melhoram a vantagem competitiva dos clientes e trazem resultados tangíveis
            às corporações, atuando como um parceiro e agente de mudança em períodos de transição e transformação.</p>
        </div>
        <div>
          <h3>Um ímpeto. Uma paixão. Uma conexão.</h3>
          <p>Em 2004, a A&M inaugurou o seu primeiro escritório no Brasil,
            em São Paulo, e, desde então, vem alavancando os resultados dos
            clientes nas mais diversas indústrias para converter a mudança
            em ativos comerciais estratégicos, gerenciar riscos e gerar valor
            em cada etapa de crescimento das empresas. A abordagem abrangente de
            resolução de problemas da A&M promove as mudanças necessárias para otimizar
            desempenho e maximizar o valor. Nossos líderes seniores e suas equipes
            ajudam as organizações a transformar as suas operações, impulsionar
            o crescimento e acelerar os resultados a partir de ações decisivas.</p>
        </div>
        <img alt='Linha preta para separação de texto' className='separator-desktop' src={widthWindow > 992 ? separatorDesktop : separatorMobile} />
        <div>
          <img alt='Somas' src={somas} />
          <p>
            A SOMAS é uma organização sem fins lucrativos que se dedica a construir
            alternativas no campo da inclusão digital, por meio da promoção de pesquisas
            e diagnósticos estruturais, adaptação e produção de conteúdos, formação de
            professores e criação de artefatos educativos. Nossa missão é reduzir distâncias
            aproximando as fronteiras digitais. Nosso intuito é expandir a compreensão dos
            alunos sobre a importância da aprendizagem, sensibilizar e inspirar pessoas a
            transformarem a si e seus meios, estimulando o espírito colaborativo e a inteligência coletiva.
            Nossa essência é desenvolver as habilidades individuais dos alunos para que eles aprendam a
            aprender e se convertam, eles também, em agentes pulverizadores de uma perspectiva mais
            humanizada do pensamento computacional.
          </p>
        </div>
      </div>
      <Button>Impulsione-se agora. Faça sua inscrição.</Button>
    </div>
  );
}

export default Creators;