import React from 'react';
import './ProjectModal.scss';

const ProjectModal = ({ data, onClose }) => {

  const handleRedirect = () => {
    window.open(data.urlProject, '_blank');
  }
  return (
    <div className={`ProjectModal ${data ? "active" : ""}`}>
      <div className="modal-status-preview">
        <div className='close-modal'>
          <button onClick={onClose} className="x-container">
            <div className="x-line x-line-left"></div>
            <div className="x-line x-line-right"></div>
          </button>
        </div>
        <div className="box-input-create">
          <div className="info-project">
            <h3>{data.name}</h3>
            <img src={data.photo} alt="Idealizador do projeto" />
            <div className="container-info">
              <p><strong>Idade: </strong> {data.age}</p>
              <p><strong>Projeto: </strong> {data.project}</p>
              <p><strong>Descrição: </strong> {data.description}</p>
              <p><strong>Contato: </strong> <a href={`mailto:${data.email}`}>{data.email}</a></p>
            </div>
            <button onClick={() => handleRedirect()} className='open-project'>ACESSE A LANDING DO PROJETO</button>
          </div>
          <div className="container-video">
            <div className='video'>
              <video autoPlay type="video/webm" src={data.video} controls width="100%">
              </video>
              {/* <div className='container-button'>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectModal
