import React from 'react';
import "./Journey.scss";

import apresentationMobile from '../../../../assets/images/apresentation-mobile.png';
import apresentation from '../../../../assets/images/apresentation.png';
import education from '../../../../assets/images/education.png';
import logoImpulso from '../../../../assets/images/logo-impulso.png';
import logoSomas from '../../../../assets/images/logo-somas.png';
import regulation from '../../../../assets/images/regulation-desktop.png';
import Button from '../../../common/Button/Button';
import BottomJourney from '../BottomJourney/BottomJourney';
import KnowTheProgram from '../KnowTheProgram/KnowTheProgram';

const Journey = () => {
  return (
    <div className='Journey' id='impulso'>
      <div className='container-journey'>
        <img className='logo-impulso' alt='logo da Impulso' src={logoImpulso} />
        <h1>Um programa <img alt='Palavra educacional' src={education} />  de inovação,
          empreendedorismo e impacto social para jovens em situação de vulnerabilidade social.</h1>
        <div className='apresentation-desktop'>
          <img alt='Imagem de apresentação' src={apresentation} />
          <div className='button-apresentation'>
            <Button>Impulsione-se agora. Faça sua inscrição.</Button>
            <a href="https://docs.google.com/document/d/e/2PACX-1vTRfQndohQFF4lmQKvObY8kzsvHpePEmnV60c0Is8vBTFZC4q9Yt1DRCGDwMpfSXA/pub"
              target="_blank" rel="noreferrer" >
              <img alt='Imagem de regulamento' src={regulation} />
            </a>
          </div>
        </div>
        <div className='realization'>
          <a href="https://somas.org.br/" rel="noopener noreferrer" target="_blank">
            <p>Organização: </p>
            <img alt='logo da Somas' src={logoSomas} />
            <p>A ONG da <strong>Mastertech</strong></p>
          </a>
        </div>
      </div>
      <img alt='Imagem de apresentação mobile' className='apresentation-mobile' src={apresentationMobile} />
      <Button active>Inscreva-se</Button>
      <KnowTheProgram />
      <BottomJourney />
    </div>
  );
}

export default Journey;