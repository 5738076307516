import React from 'react';
import impulseIs from '../../../../assets/images/impulse-is.png';
import Button from '../../../common/Button/Button';
import journeyMobile from '../../../../assets/images/journey-mobile.png';
import trailsMobile from '../../../../assets/images/trails-mobile.png';
import "./BottomJourney.scss";

const BottomJourney = () => {
  const widthWindow = window.innerWidth;

  return (
    <div className='BottomJourney'>
      <div className='container-bottom'>
        <div className='images-container'>
          <img alt='Imagem escrita Impulso é' src={impulseIs} />
        </div>
        <p>
          força <br/> reação <strong>movimento.</strong>
        </p>
      </div>
      <div className='infos-bottom-journey'>
        <div className='boost-now'>
          <p>Impulsione-se agora. Faça sua inscrição.</p>
          <Button active onClick={() => {/*TODO*/ }}>Inscreva-se</Button>
        </div>
        <p>
          <strong>É reação a partir de uma ação.</strong> É decisão. Impensada? Nem tanto.
          Ou pode ser. Sim, é movimento. É o que pulsa, empurra para frente,
          a diante. Quantas vezes um empurrão precisa ser dado para nos lançar ao novo?
          um ímpeto. uma paixão. uma conexão.
        </p>
        <p>
          Seja uma situação que te lança ou uma mão amiga – ou seria um ombro? – que acolhe.
          São ideias que intrigam, questionam, integram e empurram. Sempre para frente. São novas chances.
          Oportunidades. Acesso para o novo. Esse é o <strong>impulso da A&M,</strong> um projeto de empreendedorismo social
          que fomenta a inovação, a educação e as possibilidades para jovens em situação de vulnerabilidade.
        </p>
      </div>
     {widthWindow < 1024 && <> <img className='journey-container' alt='Imagem de jornada de 10 meses' src={journeyMobile} />
      <div className='course-trails'>
        <p>Cada uma das <strong>4 fases</strong> do programa terá:</p>
        <img alt='Imagem de trilhas do curso' src={trailsMobile} style={{ width: "70%" }} />
      </div> </>}
    </div>
  );
}

export default BottomJourney;