import React from 'react';
import './CardProject.scss';

const CardProject = ({ data, onOpenModal }) => {
  return (
    <div className='CardProject'>
      <img src={data.photo} alt="Idealizador do projeto" />
      <div className="container-info">
        <h3>{data.name}</h3>
        <p><strong>Idade: </strong> {data.age}</p>
        <p><strong>Projeto: </strong> {data.project}</p>
        <p><strong>Descrição: </strong> {data.description}</p>
      </div>
      <button className='open-modal' onClick={onOpenModal}>SAIBA MAIS</button>
    </div>
  )
}

export default CardProject
