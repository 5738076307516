import React from 'react';
import imageKnow from '../../../../assets/images/know-the-program.png';
import arrow from '../../../../assets/images/arrow-buttom.png';
import Button from '../../../common/Button/Button';
import participate from '../../../../assets/images/participate.png';
import make from '../../../../assets/images/make.png';
import inspire from '../../../../assets/images/inspire.png';

import "./KnowTheProgram.scss"

const KnowTheProgram = () => {
  return (
    <div className='KnowTheProgram'>
      <img alt='Conheça o programa' className='program' src={imageKnow} />
      <img alt='Flecha para baixo' className='arrow' src={arrow} />
      <div className='description-program'>
        <p>O Impulso tem como objetivo acelerar propostas de alto impacto social a partir da capacitação
          de jovens empreendedores que, com a aquisição de novas competências, como programação e desenvolvimento
          de produtos, podem transformar o cenário das comunidades onde estão inseridos.
          Este desafio combina, em sua fase inicial, a diversidade de pontos de vista individuais à seleção de ideias e migra,
          posteriormente, para a atuação colaborativa, quando as proposições incipientes, adaptadas pelos proponentes a ponto de
          se tornarem projetos, ganham robustez para receberem investimentos.</p>
        <h3>Para quem ?</h3>
        <p><strong>Para tanto, buscamos pessoas universitárias a partir dos 18 anos, com espírito empreendedor, residentes da cidade de Mariana, Ouro Preto, Catas Altas e seus distritos, </strong>
          que desejem qualificação técnica para a gestão metodológica de demandas com esse caráter cooperativo,
          bem como do fluxo criativo e de trabalho decorrente delas. Ao final, entendendo a educação como
          a prática formal ou informal de transmissão de conhecimentos para pessoas de qualquer recorte identitário,
          geográfico e econômico-social, sedimentada no pensamento crítico, esperamos construir marcas capazes
          de remodelar a realidade brasileira.</p>
      </div>
      <div className='container-know'>
        <div>
          <img alt='Participe' src={participate} />
          <p>A oportunidade de participar de um programa especialmente concebido para promover impacto social.</p>
        </div>
        <div>
          <img alt='Faça' src={make} />
          <p>Os estímulos e ferramentas necessárias para desenvolver um negócio real, usando as melhores práticas disponíveis
            e contando com a ajuda de especialistas.</p>
        </div>
        <div>
          <img alt='Inspire' src={inspire} />
          <p> A chance de participar de um processo de seleção que pode acelerar e até investir nas
            ideias que se revelarem mais promissoras e impactantes.</p>
        </div>
      </div>
      <Button onClick={() => {/*TODO*/ }}>Impulsione-se agora. Faça sua inscrição.</Button>
    </div>
  );
}

export default KnowTheProgram;