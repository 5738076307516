import React from "react";
import "./SlideCards.scss";
import Button from "../../common/Button/Button";
import creation from '../../../assets/images/creation.png';
import problem from '../../../assets/images/problem.png';
import knowledge from '../../../assets/images/knowledge.png';

const SlideCards = () => {

  return (
    <div className="SlideCards">
      <div className="container-slide" id="impacto">
        <p>Quais as características essenciais de um projeto que promova impacto social?</p>
        <div className="box-slide-desktop">
          <p>Impulsione-se agora. Faça sua inscrição.</p>
          <Button active>Inscreva-se</Button>
        </div>
      </div>
      <div className="bottom-cards">
        <div>
          <img src={creation} />
          <p>
            Criação de soluções rápidas, escaláveis e com foco na comunidade
          </p>
        </div>
        <div>
          <img src={problem} />
          <p>
            Resolver problemas reais.
          </p>
        </div>
        <div>
          <img src={knowledge} />
          <p>
            Fomento ao conhecimento e trabalho em rede.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SlideCards;
