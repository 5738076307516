export const doc = [
  {
    name: "Ana Caroline Garcia",
    age: "30 anos",
    project: "MOB HOUSE",
    description: "O MobHouse oferece mobilidade e moradia acessível através de locações flexíveis em São Paulo (além de integrar uma comunidade de negócios, produtos e serviços).",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Ana+Caroline+Garcia.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Ana.jpeg",
    urlProject: "https://mobhouse.anacarolinegarc.repl.co/",
    email: "anacaroline.garcia@gmail.com"
  },
  {
    name: "Bianca Monteiro",
    age: "26 anos",
    project: "SHIFT",
    description: "Preparar e qualificar jovens para as responsabilidades e os desafios da vida adulta.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Bianca+Monteiro+de+Oliveira.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Bianca+Monteiro.jpg",
    urlProject: "https://landing-page--biancamonteiro2.repl.co/",
    email: "biancamtr@outlook.com"
  },
  {
    name: "Camila Nicolau dos Santos",
    age: "21 anos",
    project: "DECOLA",
    description: "Desenvolvemos habilidades em jovens para enfrentar desafios reais, fortalecendo, simultaneamente, o comércio local por meio de tecnologia e inovação.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Camila+Nicolau.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Camila+Nicolau+2.jpg",
    urlProject: "https://aula-presencial-impulso.camilanicolau.repl.co/",
    email: "nicolau.camila.cn@gmail.com"
  },
  {
    name: "Felipe Machado",
    age: "27 anos",
    project: "Well Be",
    description: "Dar acesso à alimentação saudável via modelo fast food.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Felipe+Oliveira+Machado.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Felipe+Machado.jpg",
    urlProject: "https://well-be-v2.felipeolivei144.repl.co/",
    email: "felipe.machado.ismart@gmail.com"
  },
  {
    name: "Guilherme Melo Martins",
    age: "19 anos",
    project: "GREENCITY",
    description: "Levar economia e sustentabilidade por meio da geração de energia solar de uma forma acessível para todos.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Guilherme+Melo+Martins.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Guilherme+Melo+Martins.jpg",
    urlProject: "https://replit.com/@guilhermemmarti/Documento-Novo",
    email: "guilhermemmartins05@gmail.com"
  },
  {
    name: "João Vitor Amorim Borges Rosa",
    age: "22 anos",
    project: "UBUNTU DEV - PROGRAMAÇÃO AFROCENTRADA",
    description: "Projeto para ensinar jovens: programação, soft skills e inglês com foco no empoderamento.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Jo%C3%A3o+Vitor+Amorim+Borges+Rosa.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Jo%C3%A3o+Victor+Amorim.jpg",
    urlProject: "https://joaoamorim01.github.io/ubuntu_dev/",
    email: "ubuntudevcomercial@gmail.com"
  },
  {
    name: "Leticia de Paulo Melo",
    age: "27 anos",
    project: "REDE COMUNITÁRIA NOVO FUTURO",
    description: "Levar acesso digital para a comunidade, através de uma plataforma de intranet.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Let%C3%ADcia+de+Paula+Melo.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Let%C3%ADcia+de+Paula+Melo.jpg",
    urlProject: "https://redecomunitarianovofuturo.meloleticiap.repl.co/",
    email: "meloleticiap@hotmail.com"
  },
  {
    name: "Lucas Felipe Nunes de Andrade",
    age: "19 anos",
    project: "POLIMAC",
    description: "Uma escola digital que proporciona apoio para alunos de escolas públicas.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Lucas+Felipe+Nunes+de+Andrade.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Lucas+Felipe+Nunes+.jpg",
    urlProject: "https://lucasnunessss4.wixsite.com/polimac",
    email: "lucas.nunessss4@gmail.com"
  },
  {
    name: "Mariana Guimarães Marchezi Chaves",
    age: "27 anos",
    project: "MIRA, CAMINHOS SEGUROS",
    description: "Tornar visível às mulheres informações sobre trajetos e lugares inseguros nas cidades.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Mariana+Guimar%C3%A3es.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Mariana+Chaves.jpg",
    urlProject: "https://miracaminhos.wixsite.com/inicio",
    email: "marianagmchaves@hotmail.com"
  },
  {
    name: "Nicodemos Granjeiro Costa",
    age: "24 anos",
    project: "Criartividades",
    description: "Suporte a contrução de negócios criativos prósperos para permitir que indivíduos ganhem a vida fazendo o que amam.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Nicodemos+Granjeiro+Costa.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Nicodemos+Granjeiro+Costa.jpg",
    urlProject: "https://nico-demos.github.io/criartividades/",
    email: "nicodemos.ed@gmail.com"
  },
  {
    name: "Salomão Emilio Gomes da Silva",
    age: "24 anos",
    project: "Plifin",
    description: "Democratizar a educação financeira para todos, com abordagens simplificadas e ferramentas práticas.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Salom%C3%A3o+Em%C3%ADlio+Gomes+da+Silva.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Salom%C3%A3o+Em%C3%ADlio+Gomes+da+Silva.jpg",
    urlProject: "https://lpoficialsalomao.salomaoemilio.repl.co/",
    email: "salomao.emilio@gmail.com"
  },
  {
    name: "Silvani Aparecida das Chagas",
    age: "29 anos",
    project: "PERIFEMINAS",
    description: "Fomenta o futebol e contribui na formação pessoal e engajamento em causas sociais.",
    video: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/videos/Silvani+Chagas.mp4",
    photo: "https://impulso-projetos.s3.sa-east-1.amazonaws.com/imagens/Silvani+Chagas.jpg",
    urlProject: "https://replit.com/@SilvaniAparecid/Perifeminas-1",
    email: "mulherepretaemmovimento@gmail.com"
  },
];
