import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import { useLocation } from 'react-router-dom';
import logoImpulso from '../../../assets/images/logo-impulso.png';

const Navbar = () => {
  const { hash, pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [classNav, setClassNav] = useState("");

  const closeMenu = () => {
    setMenuOpen(false);
    setClassNav("menu-closed");

    setTimeout(() => {
      setClassNav("");
    }, 1200);
  };

  const handleMenu = () => {
    if (menuOpen) {
      closeMenu();
      setClassNav("menu-closed");

      setTimeout(() => {
        setClassNav("");
      }, 1200);
    } else {
      setMenuOpen(true);
      setClassNav("menu-active");
    }
  };

  useEffect(() => {
    setClassNav('menu-closed')
  }, [hash])

  return (
    <nav className={`Navbar ${classNav}`}>
      <a href={window.location.origin}>
        <img alt='Logo da Impulso' src={logoImpulso} />
      </a>
      <button aria-label={menuOpen ? "fechar menu" : "abrir menu"} onClick={handleMenu}>
        <span />
        <span />
        <span />
      </button>
      <div>
        <NavigationLink href='/#impulso' active={hash === '#impulso'}>Por que Impulso?</NavigationLink>
        <NavigationLink href='/#timeline' active={hash.includes('#timeline')}>A jornada de 10 meses</NavigationLink>
        <NavigationLink href='/#impacto' active={hash.includes('#impacto')}>O que é impacto social?</NavigationLink>
        <NavigationLink href='/#idealizadores' active={hash.includes('#idealizadores')}>Conheça os idealizadores</NavigationLink>
        <NavigationLink href='/projetos-sp' active={pathname.includes('projetos-sp')}>Projetos | Edição SP</NavigationLink>
      </div>
    </nav>
  )
}

export default Navbar;
