import React, { useEffect, useState } from 'react'
import CardProject from '../../common/CardProject/CardProject'
import ProjectModal from '../../common/ProjectModal/ProjectModal'
import './Projects.scss'
import { doc } from './projectsDoc'

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleOpenModal = (projectId) => {
    setSelectedProject(projectId);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  useEffect(() => {
    if (doc[selectedProject]) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [handleCloseModal, handleOpenModal]);


  return (
    <div className={`Projects ${selectedProject ? "active" : ""}`}>
      <div className="container-title">
        <h4>Conheça de perto os projetos que emergiram ao longo de 10 meses do Programa Impulso,  moldadas por mentes empreendedoras empenhadas em fazer a diferença.</h4>
      </div>
      <div className="container-cards">
        {doc.map((item, index) => {
          return <CardProject key={index} data={item} onOpenModal={() => handleOpenModal(index)} />
        })}
      </div>

      {selectedProject !== null && (
        <ProjectModal
          data={doc[selectedProject]}
          onClose={handleCloseModal}
        />
      )}
    </div>
  )
}

export default Projects
