import React from 'react';
import "./Timeline.scss";
import timeline from '../../../assets/images/timeline.png';
import timelineMobile from '../../../assets/images/timeline-mobile.png';

const Timeline = () => {
  const widthWindow = window.innerWidth;

  const renderImage = () => {
    if (widthWindow > 768) {
      return <img alt='Imagem de linha do tempo do curso' src={timeline} />
    } else {
      return <img alt='Imagem de linha do tempo do curso' src={timelineMobile} />
    }
  }

  return (
    <div className='Timeline' id='timeline'>
      {renderImage()}
    </div>
  );
}

export default Timeline;