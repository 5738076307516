import React from 'react';
import './Button.scss';

const Button = ({ onClick, children, active, href }) => {
  return (
    <div className='ButtonContainer'>
      <a href="https://mastertech-tech.typeform.com/to/zuI3smRl#email=xxxxx" target="_blank" rel="noreferrer">
        <button className={`Button  ${active ? "active" : ""}`} onClick={onClick}>
          {children}
        </button>
      </a>
      <a className='regulation' href={"https://docs.google.com/document/d/e/2PACX-1vTRfQndohQFF4lmQKvObY8kzsvHpePEmnV60c0Is8vBTFZC4q9Yt1DRCGDwMpfSXA/pub"}
        target="_blank" rel="noreferrer">
        Consulte aqui o regulamento
      </a>
    </div>
  )
}

export default Button;
