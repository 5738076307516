import React from "react";
import "./Footer.scss";
import { ReactComponent as Ig } from "../../../assets/images/ig.svg";
import { ReactComponent as Location } from "../../../assets/images/location.svg";
import { ReactComponent as Mail } from "../../../assets/images/mail.svg";
import somas from "../../../assets/images/somas-white.png";
import whatsapp from "../../../assets/images/whatsapp.png";
import whatsappMobile from "../../../assets/images/whatsapp_mobile.png";

const Footer = () => {
  const widthWindow = window.innerWidth;

  return (
    <footer className="Footer">
      <img alt="Logo da Somas" className="logo-somas" src={somas} />
      <div>
        <div>
          <Location />
          <div>
            <p>Av. Brigadeiro Luis Antônio, 2696, Jardim Paulista</p>
            <p>São Paulo - SP 01402-000</p>
          </div>
        </div>
        <div>
          <a href="https://www.instagram.com/somas.org.br/" rel="noopener noreferrer"><Ig /></a>
          <div>
            <p>somas.org.br</p>
          </div>
        </div>
        <div>
          <a href="mailto:contato@somas.org.br"><Mail /></a>
          <div className="whats-container">
            <a href="mailto:contato@somas.org.br">contato@somas.org.br</a>
            <a target="_blank"
              href="https://api.whatsapp.com/send?phone=5511915809615&text=Ol%C3%A1%2C%20desejo%20tirar%20d%C3%BAvidas%20sobre%20o%20programa%20Impulso.">+55 11 91580-9615</a>
            <a target="_blank"
              href="https://api.whatsapp.com/send?phone=5511915809615&text=Ol%C3%A1%2C%20desejo%20tirar%20d%C3%BAvidas%20sobre%20o%20programa%20Impulso.">
              <img alt="Logo da Somas" className={widthWindow > 768 && "whatsapp"} src={widthWindow > 768 ? whatsapp : whatsappMobile} />
            </a>
          </div>
        </div>
        <div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
