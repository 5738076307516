import { BrowserRouter, Route, Routes } from "react-router-dom";
import GenericPage from "./common/GenericPage/GenericPage";
import Creators from "./pages/Creators/Creators";
import Projects from "./pages/Projects/Projects";
import SlideCards from "./pages/SlideCards/SlideCards";
import Timeline from "./pages/Timeline/Timeline";
import Journey from "./pages/journey/Journey/Journey";
import Footer from "./ui/Footer/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GenericPage>
            <Journey />
            <Timeline />
            <SlideCards />
            <Creators />
            <Footer />
          </GenericPage>} />
          <Route path="/projetos-sp" element={<GenericPage><Projects /></GenericPage>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
